<template>
  <div class="order_container">
    <van-cell-group>

      <van-cell title="门诊缴费记录"
                icon="balance-list-o"
                is-link
                @click="navOutpatient" />
      <van-cell title="预约挂号待缴费订单"
                icon="bill-o"
                is-link
                @click="navRegister" />
      <!-- <van-cell
        title="体检预约订单"
        icon="orders-o"
        is-link
        @click="navExamine"
      /> -->
    </van-cell-group>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Order",
  data () {
    return {};
  },
  created () { },
  methods: {
    navRegister () {
      this.$router.push({
        path: "/order/register"
      });
    },
    navOutpatient () {
      this.$router.push({
        path: "/order/outpatient"
      });
    },
    navDeposit () {
      this.$router.push({
        path: "/order/deposit"
      });
    },
    navExamine () {
      this.$router.push({
        path: "/order/examine"
      });
    }
  }
};
</script>
<style scope>
.order_container .van-cell__title {
  font-size: 40px;
}
.order_container .van-cell {
  padding: 30px;
}
.order_container .van-cell__left-icon,
.order_container .van-cell__right-icon {
  color: #1989fa;
  font-size: 40px;
}
</style>